




























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Playlist} from '@/types';
import UBlock from '@/ui-lib/block/Block.vue';
import PlaylistFormView from '@/components/playlist/PlaylistForm.vue';

@Component<PlaylistItemInfoView>({
  components: {
    UBlock,
    PlaylistFormView,
  },
})
export default class PlaylistItemInfoView extends Vue {
  @Prop({required: true})
  private playlist!: Playlist;
}
